import React from 'react'
import { Global, css } from '@emotion/core'

export const globalStyles = `
    h1, h2, h3, h4, h5, h6 {
      margin-top:0;
    }

    img {
      max-width: 100%;
      height: auto
    }

    .gatsby-image-wrapper {
      width: 100%
    }

    .screenReader {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
    }

    .screenReaderMobile {
      @media (max-width: 768px )  {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
      }
    }
`

export const GlobalStyles = () => (
  <Global
    styles={css`
      ${globalStyles}
    `}
  />
)
const breakpoints = ['768px', '1024px', '1280px', '1600px']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const mq = breakpoints.map((bp) => `@media (min-width: ${bp})`)

mq.sm = mq[0]
mq.md = mq[1]
mq.lg = mq[2]
mq.xl = mq[3]

export { mq }

const colors = {
  orange: '#F08A4B',
  green: '#61AA72',
  red: '#B90000',
  blue: ['#DBE4EE', '#365A85', '#2F4858'],
  white: '#ffffff',
  black: '#000000',
}

export const theme = {
  breakpoints,
  colors: {
    ...colors,
    muted: colors.blue[0],
    primary: colors.blue[2],
    secondary: colors.blue[1],
    text: colors.primary,
    background: colors.white,
    accent: colors.orange,
    highlight: colors.green,
    warn: colors.red,
  },
  fontSizes: [14, 16, 18, 20, 24, 36, 49],
  fonts: {
    body: `'Open Sans', sans-serif`,
    heading: `'Lato', sans-serif`,
  },
  fontWeights: {
    body: 300,
    heading: 300,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.387,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeights: 'heading',
    },
  },
  forms: {
    input: {
      fontFamily: 'body',
    },
    textarea: {
      fontFamily: 'body',
    },
  },
  variants: {
    container: {
      maxWidth: `1260px`,
      mx: 'auto',
      px: 30,
    },
  },
  radii: [16, 32, 36],
  space: [0, 4, 8, 12, 32, 54, 128, 256, 512],
  buttons: {
    disabled: {
      background: 'black',
    },
    outline: {
      border: '2px solid',
      borderRadius: 2,
      px: 5,
      py: 3,
      bg: 'transparent',
      color: 'primary',
    },
    defaultSmall: {
      cursor: 'pointer',
      border: '2px solid',
      borderRadius: 2,
      px: 4,
      py: 2,
      bg: 'primary',
      color: 'white',
      transition: '.5s all ease',
      '&:hover': {
        background: 'transparent',
        color: 'primary',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      color: 'primary',
      margin: 0,
    },
    p: {
      fontSize: [1, 2],
      whiteSpace: 'pre-wrap',
    },
    a: {
      fontSize: [1, 2],
      color: 'primary',

      '&:hover': {
        color: 'green',
      },
    },
    h1: {
      variant: 'text.heading',
      fontSize: [5, 6],
    },
    h2: {
      variant: 'text.heading',
      fontSize: [4, 5],
    },
    h3: {
      variant: 'text.heading',
      fontSize: [3, 4],
      fontWeight: 'normal',
      mb: [2],
    },
    h4: {
      variant: 'text.heading',
      fontSize: [3],
      fontWeight: 'bold',
      mb: 1,
    },
    h5: {
      variant: 'text.heading',
      fontSize: [3],
      fontWeight: 'bold',
    },
    h6: {
      variant: 'text.heading',
      fontSize: [3],
      fontWeight: 'bold',
    },
  },
}
