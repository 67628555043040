module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-170813354-1","head":true,"anonymize":true,"respectDNT":true,"defer":false,"cookieDomain":"accountablebookkeeping.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-relative-images","id":"8725fafd-5e32-5cbd-9f30-0c2b80a5846d","name":"gatsby-remark-relative-images","version":"0.3.0","pluginOptions":{"plugins":[],"name":"uploads"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"2d241c37-172f-570f-be71-e47d0e016c38","name":"gatsby-remark-images","version":"3.3.17","pluginOptions":{"plugins":[],"maxWidth":2048},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"31dd1074-cb99-53b7-9eca-c0c85e1e1419","name":"gatsby-remark-copy-linked-files","version":"2.3.10","pluginOptions":{"plugins":[],"destinationDir":"static"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
