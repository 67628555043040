// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-tsx": () => import("./../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../src/templates/article-page.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-articles-page-tsx": () => import("./../src/templates/articles-page.tsx" /* webpackChunkName: "component---src-templates-articles-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../src/templates/service-page.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */)
}

