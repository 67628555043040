import React from 'react'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import { theme, GlobalStyles } from './index'

export const ThemeProvider = ({ children }) => (
  <EmotionThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </EmotionThemeProvider>
)
